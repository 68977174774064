import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './VehicleRemindersCard.module.scss';
import TextButton from '../../../shared/components/TextButton/TextButton';
import ReminderItem from './ReminderItem';
import SMRBanner from './SMRBanner';
import Spinner from '../../Spinner';
import { COVER_STATES } from '../../../constants/vehicleConstants';
import { formatReminderItems } from '../../../utils/vehicleHelpers';
import { getVehicleMotHistory } from '../../../services/MyRacService';

const VehicleRemindersCard = ({ session, vehicle, coverStatus, coverText, remindersList, vehicleEditOnClick, reminderEditOnClick, motHistoryButtonClick, setShowVehicleHistoryModal, setApiRequestFired, vehicleRemindersCardLoading, reminderSMRData }) => {
  const [remindersListState, setRemindersListState] = useState();
  const [coverStatusStyles, setCoverStatusStyles] = useState(styles.statusInactive);
  const [loading, setLoading] = useState(false);

  const vehicleName = `${vehicle.Make} ${vehicle.Model}`;

  // TODO: What are the correct colours for each cover state?
  const styleSelector = () => {
    const activeList = [COVER_STATES.IS_COVERED_BY_BREAKDOWN, COVER_STATES.IS_COVERED_FOR_REGISTERED_USERS, COVER_STATES.IS_HYBRID_COVERED_BY_BREAKDOWN, COVER_STATES.IS_MEMBERSHIP_ONLY, COVER_STATES.IS_PAY_AS_YOU_GO_COVERED];
    // const inActiveList = [COVER_STATES.IS_NOT_ACTIVE, COVER_STATES.IS_NOT_ACTIVE_BREAKDOWN, COVER_STATES.IS_NOT_ACTIVE_REGISTERED_USER, COVER_STATES.IS_PAY_AS_YOU_GO_NOT_COVERED, COVER_STATES.NONE];

    if (activeList.includes(coverStatus)) {
      return styles.statusActive;
    }
    return styles.statusInactive;
  };

  const fetchMotData = async () => {
    setLoading(true);
    getVehicleMotHistory(vehicle.Registration, session)
      .then((response) => {
        if (response) {
          motHistoryButtonClick(vehicle, response.payload);
        } else {
          motHistoryButtonClick(vehicle, null);
        }
      })
      .catch(motHistoryButtonClick(vehicle, null))
      .finally(() => { setLoading(false); setShowVehicleHistoryModal(true); });
  };

  useEffect(() => {
    setRemindersListState(formatReminderItems(remindersList));
    setCoverStatusStyles(styleSelector());
    setApiRequestFired(false);
  }, [remindersList]);

  return (
    <div className={`${styles.container} ${coverStatusStyles}`}>
      {vehicleRemindersCardLoading ? <div className={styles.spinnerContainer}><Spinner addClass="pt-0" bootSpinner /></div> : (
        <>
          <div className={styles.vehicleContainer}>
            <div className={styles.headerContainer}>
              <span className={`${styles.registration} ${coverStatusStyles} sessioncamhidetext`}>{vehicle.Registration}</span>
              <TextButton buttonText="Edit" orange textUnderline noPadding onClickHandler={vehicleEditOnClick} />
            </div>
            <h3 className={`${styles.vehicleName} ${coverStatusStyles}`}>{vehicleName}</h3>
            <div className={`${styles.statusText} ${coverStatusStyles}`}>{coverText}</div>
          </div>
          <div className={styles.verticalLine} />
          <div className={styles.vehicleRemindersContainer}>
            <div className={styles.headerContainer}>
              <h4 className={styles.title}>Reminders</h4>
              <TextButton buttonText="Edit" orange textUnderline noPadding onClickHandler={reminderEditOnClick} />
            </div>
            {reminderSMRData?.heroBanner && remindersListState?.service && vehicle?.Registration && (
              <SMRBanner
                data={reminderSMRData.heroBanner}
                vehicleRegistration={vehicle.Registration}
                serviceDate={remindersListState?.service?.date}
              />
            )}
            <div className={styles.reminderContainer}>
              <ReminderItem reminder={remindersListState?.service} reminderSMRBtn={reminderSMRData?.remindersMoreInfo} vehicleRegistration={vehicle.Registration} />
              <ReminderItem reminder={remindersListState?.tax} />
              <ReminderItem reminder={remindersListState?.mot} buttonText="View MOT history" buttonAction={fetchMotData} loading={loading} />
              <ReminderItem reminder={remindersListState?.insurance} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(VehicleRemindersCard);
