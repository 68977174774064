import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './GridLayout.scss';
import { loggerFunc } from 'utils/logger';
import Alert from 'components/Alert';
import OrangePointsModal from 'components/OrangePointsModal';
import PopupModal from 'components/PopupModal/PopupModal';
import Spinner from 'components/Spinner';
import PrizeIcon from 'assets/images/orangepoints/prize-orange.svg';
import * as Sentry from '@sentry/react';
import useViewport from '../../../../utils/useViewportHelper';
import { SourceCodes } from '../../../../constants/enums';
import WelcomeTile from '../../../Widgets/WelcomeTile';
import { GetContent, GetTileContent } from '../../../../services/ContentService';
import { GetRenewalPopup, GetRenewalEligibility, GetTescoLoginJID } from '../../../../services/MyRacService';
import { ResponseCode } from '../../../../services/models/httpResponse';
import TescoPanel from '../../../../panels/TescoPanel';
import Efulfilment from '../../../../modals/Efulfilment';
import * as SessionActions from '../../../../actions/sessionActions';
import { eFulfilmentErrorMessages } from '../../../../constants/cms/messageConstants';
import { getMessage } from '../../../../services/MessageService';
import trackEvent from '../../../../services/AnalyticsService';
import AbstractGrid from './AbstractGrid';
import TileSets from '../../../../utils/tileSets.enum';
import { VEHICLE_DETAILS, RENEWAL, ORANGE_POINTS_JOURNEY, LOGIN } from '../../../../constants/routeConstants';
import { useApplicationState } from '../../../../hooks/applicationState-Context';
import { useUserJourney } from '../../../../hooks/userJourney-Context';

const logger = loggerFunc('GridLayout');

export const GridLayout = (props) => {
  const {
    customer: { custInfo },
    policy: { policyInfo },
    vehicles: { vehicleInfo },
    tiles: { membershipOverview },
    updateEfulfilmentShown,
    updateTescoPopupShown,
    updateOrangePointsShown,
    updateReminderAlertShown,
    updateRenewBannerShown,
    storeRenewBannerImgUrl,
    session,
  } = props;

  const { state } = useLocation();
  const { appState, setPageLoading } = useApplicationState();
  const { userJourneyState } = useUserJourney();

  const [pageModel, setPageModel] = useState(null);
  const [eFulfilmentError, setEFulfilmentError] = useState(false);
  const [reminderAlert, setReminderAlert] = useState(false);
  const [redeemedAlert, setRedeemedAlert] = useState(false);
  const [renewNowBanner, setRenewNowBanner] = useState(false);
  const [renewNowBannerImage, setRenewNowBannerImage] = useState();
  const [tescoRenew, setTescoRenew] = useState(false);
  const [orangePoints, setOrangePoints] = useState(false);
  const [tescoJID, setTescoJID] = useState();
  const [renewNowStatus, setRenewNowStatus] = useState();

  const MAX_COLS = 4;
  const notificationLinks = [];

  // Flag for initial login by determining that last location was the login page
  const isLogin = userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2] === LOGIN;

  // Boolean used to determine whether last location was orange points journey for switching between alert shown
  const orangePointsJourney = userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2] === ORANGE_POINTS_JOURNEY;

  // Captures a change to the mobile layout and causes a new render with subsequent re-request of the grid data
  const currentMobileState = useViewport().isBootstrapSm;

  // PopupModal override styles for 'Renew Now' banner as part of Renewals promotion
  const renewBannerStyle = {
    backgroundImage: `url(${renewNowBannerImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    height: currentMobileState ? '97vw' : '570px',
    marginTop: currentMobileState ? '70px' : '100px',
  };
  // Area map of where clickable
  const renewBannerButton = {
    margin: currentMobileState ? '60% auto' : '75% auto',
    height: '5rem',
    width: '70%',
  };

  // Check session vehicle data to determine whether to display 'Add Reminders' alert
  const vehicleReminderInfo = () => {
    // eslint-disable-next-line prefer-const
    let reminderDates = [];
    const vehicles = vehicleInfo?.reminderVehicles;
    if (vehicles?.length > 0) {
      vehicles.forEach((vehicle) => {
        const { reminders } = vehicle;
        if (reminders?.length > 0) {
          reminders.forEach((reminder) => {
            if (!reminder?.date) {
              // Null date value means reminder has not been set
              reminderDates.push('Not set');
            }
          });
        }
      });
    }
    // Show alert if no vehicles found or reminderDates[] contains values where reminders 'Not set'
    if (!vehicles?.length || reminderDates?.length > 0) {
      setReminderAlert(true);
    }
  };

  // Welcome content for the welcome tile is independent of the Grid tile data
  const welcomeContent = async () => GetContent('membershipoverviewpage', 'Membership Overview Desktop').then((response) => response).catch((err) => logger.debug(err));

  // Determine popup to be shown based on eligibility
  const fetchPopupEligibility = async () => {
    try {
      const response = await GetRenewalEligibility(custInfo.Id);
      if (response.payload) {
        const { isEligibleForRenewal, hasOrangePointsRewards } = response.payload;
        if (isEligibleForRenewal) {
          // Tesco popup shown if used referral link and is eligible for Renewal
          if (session?.isTescoLogin || policyInfo?.sourceCode === SourceCodes.RWAFTS2) {
            GetTescoLoginJID(custInfo.Id, session.memberNo).then((res) => {
              setTescoJID(res?.payload);
            }).catch((err) => logger.error(err));
            setTescoRenew(true);
            trackEvent('MyRAC_Tesco_PopupDisplayed');
          } else {
            // Renewal popup
            GetRenewalPopup(custInfo.Id).then(async (res) => {
              if (res?.payload) {
                const { url, fileName } = res.payload;
                // Add image url to page state
                setRenewNowBannerImage(url);
                // Store image url in session state for use on other pages
                storeRenewBannerImgUrl(url);
                // Image defines cover status therefore slice file extention from filename and use to set value in state
                const coverStatus = fileName && (fileName).slice(0, -4) === 'RenewNowPopup' ? 'renew' : 'expired';
                // Fallback in case filename uploaded to cms fails to match pattern above
                setRenewNowStatus(coverStatus ?? 'renew');
                // Popup not to be shown if member was redirected via deep link or has already visited the Renewal page
                const visitedRenewalPage = userJourneyState?.pageHistory.includes(RENEWAL);
                if (!visitedRenewalPage) {
                  setRenewNowBanner(true);
                  if (coverStatus) {
                    trackEvent(`ukbc.${coverStatus}popupdisplayed`);
                  }
                }
              }
            }).catch((err) => logger.debug(err));
          }
        }
        // Orange Points popup
        if (hasOrangePointsRewards && !isEligibleForRenewal && !session?.isTescoLogin) {
          setOrangePoints(true);
        }
      }
    } catch (err) {
      logger.debug(err);
    }
  };

  const sentryLogging = () => {
    // Check for lead member discrepancy
    const leadMember = policyInfo?.Members?.find((m) => m?.LeadMember);
    if (leadMember) {
      const customerName = custInfo.Forename + custInfo.Surname;
      const leadMemberName = leadMember.FirstName + leadMember.Surname;
      if (customerName !== leadMemberName) {
        Sentry.captureMessage(`Lead member details do not match (${custInfo?.Id})`);
      }
    }
  };

  useEffect(() => {
    if (appState?.loading?.status && custInfo && custInfo?.Id) {
      // Reminders
      vehicleReminderInfo();
      // Popup modal
      fetchPopupEligibility();
      // Grid tiles
      GetTileContent(custInfo.Id, session?.memberNo, TileSets?.membershipOverview, currentMobileState).then(() => {
        welcomeContent().then(async (result) => {
          if (result?.responseCode === ResponseCode?.SUCCESS) {
            await setPageModel(result?.payload);
          }
          setPageLoading(false, null);
        }).catch((err) => logger.debug(err));
      });
      // Sentry
      if (isLogin) {
        sentryLogging();
      }
    }
  }, [custInfo?.Id, session?.memberNo, appState?.loading?.status, custInfo, currentMobileState]);

  const toggleShowEFulfilment = (event) => {
    event.preventDefault();
    updateEfulfilmentShown(true);
  };

  const toggleFulfilmentError = () => {
    setEFulfilmentError(!eFulfilmentError);
    updateEfulfilmentShown(true);
  };

  const toggleTescoPopup = () => {
    // Close modal window
    setTescoRenew(false);
    // Store action in session so that popup is not displayed again until next user session
    updateTescoPopupShown(true);
  };

  const toggleOrangePoints = () => {
    // Close modal window
    setOrangePoints(false);
    // Store action in session so that popup is not displayed again until next user session
    updateOrangePointsShown(true);
  };

  const toggleRenewNowBanner = () => {
    setRenewNowBanner(!renewNowBanner);
    updateRenewBannerShown(true);
    trackEvent(`ukbc.${renewNowStatus}popupclosed`);
  };

  const toggleAddReminderAlert = () => {
    // Hide alert using state
    setReminderAlert(!reminderAlert);
    // Store action in session so that alert is not displayed again until next user session
    updateReminderAlertShown(true);
  };

  const toggleRedeemedAlert = () => {
    // Hide alert using state
    setRedeemedAlert(!redeemedAlert);
  };

  return (
    <>
      { pageModel && custInfo && membershipOverview ? (
        <>
          <WelcomeTile pageModel={pageModel} notifications={notificationLinks} />
          {/* Add reminders alert */}
          {!session?.reminderAlertShown && reminderAlert && !orangePointsJourney && (
            <Alert
              toggleShow={toggleAddReminderAlert}
              heading="Never miss a key date again"
              message="You’re missing a few vehicle reminders. Add them now so you don’t miss them."
              eventTag="myrac.vehicleremindernotification"
              buttonText="Add reminders"
              linkRef={VEHICLE_DETAILS}
              isButton
            />
          )}
          {/* Orange points redeemed alert */}
          {state?.isOrangePointsRedeemed && !redeemedAlert && orangePointsJourney && (
            <div className="alert row bg-orange text-white fs-5 fw-bold mx-1 alert-dismissible fade show" role="alert">
              <div className="position-absolute top-50 start-0 translate-middle-y">
                <img src={PrizeIcon} alt="Prize" height="40px" width="40px" />
              </div>
              <div className="col-10 mx-5 p-1">
                {state?.memberChoseRenewalDiscount ? (
                  'Your discount will be updated in your renewal docs soon!'
                ) : (
                  'Your mobile mechanic voucher will be on its way soon!'
                )}
              </div>
              <button type="button" onClick={toggleRedeemedAlert} className="btn-close btn-close-white p-4" data-bs-dismiss="alert" aria-label="Close" />
            </div>
          )}
          {/* Grid Layout */}
          <div className="grid-wrapper" data-testid="GridLayoutContainer">
            <AbstractGrid columns={MAX_COLS} tiles={membershipOverview} />
          </div>
        </>
      ) : null }
      {tescoRenew && !renewNowBanner && !session?.tescoPopupShown && (
        <PopupModal
          resultTitle="Tesco Clubcard"
          form={tescoJID ? <TescoPanel jid={tescoJID} /> : <Spinner defaultSpinner />}
          action={() => toggleTescoPopup()}
          headerOverrideStyle={{ textAlign: 'center' }}
          innerCloseButton
        />
      )}
      {orangePoints && !session?.orangePointsShown && !tescoRenew && !renewNowBanner && (
        <OrangePointsModal action={() => toggleOrangePoints()} />
      )}
      {
        pageModel && session?.efulFilmentShown && !custInfo?.WebRegistered
        && !custInfo?.isPaperLess && !eFulfilmentError
        && <Efulfilment toggleShowModal={toggleShowEFulfilment} toggleHideEFulfilmentModal={toggleFulfilmentError} />
      }
      {eFulfilmentError && (
        <PopupModal
          modalOk="Ok"
          resultTitle={getMessage(eFulfilmentErrorMessages.ERROR_HEADER)}
          resultText={getMessage(eFulfilmentErrorMessages.ERROR_SUBTEXT)}
          alignBodyCenter
          alignHeaderCenter
          displayDialogAtTopQuarter
          action={toggleFulfilmentError}
          innerCloseButton
        />
      )}
      {renewNowBanner && !session?.renewBannerShown && !orangePoints && !tescoRenew && (
        <PopupModal
          action={toggleRenewNowBanner}
          bannerCloseButton
          backgroundOverrideStyle={renewBannerStyle}
          backgroundButtonMap={renewBannerButton}
          backgroundButtonAlt="Renew now"
          eventTag={`ukbc.${renewNowStatus}popupclicked`}
          linkRef={RENEWAL}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
  customer: state.customer,
  session: state.session,
  vehicles: state.vehicles,
  featureFlags: state.featureToggle,
  tiles: state.content.tiles,
});

const mapDispatchToProps = (dispatch) => ({
  updateEfulfilmentShown: (efulfilmentShown) => {
    dispatch(SessionActions.setEfulfilmentShown(efulfilmentShown));
  },
  updateTescoPopupShown: (tescoPopupShown) => {
    dispatch(SessionActions.setTescoPopupShown(tescoPopupShown));
  },
  updateOrangePointsShown: (orangePointsShown) => {
    dispatch(SessionActions.setOrangePointsShown(orangePointsShown));
  },
  updateReminderAlertShown: (reminderAlertShown) => {
    dispatch(SessionActions.setReminderAlertShown(reminderAlertShown));
  },
  updateRenewBannerShown: (renewBannerShown) => {
    dispatch(SessionActions.setRenewBannerShown(renewBannerShown));
  },
  storeRenewBannerImgUrl: (renewBannerImgUrl) => {
    dispatch(SessionActions.setRenewBannerImgUrl(renewBannerImgUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GridLayout);
