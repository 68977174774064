import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MY_COVER, PRODUCT_UPGRADE_JOURNEY, PRODUCT_UPGRADE_PAGE, PERSONAL_DETAILS, DOCUMENTS, VEHICLE_DETAILS, ACCOUNT_DETAILS, MEMBER_REWARDS, LOGIN, ROOT, FAQS, TERMS_CONDITIONS, FORGOT_PASSWORD, UPDATE_PASSWORD, PAYMENT_ISSUE } from '../../constants/routeConstants';
import { useUserJourney } from '../../hooks/userJourney-Context';
import BackButton from '../../shared/components/BackButton/BackButton';
import styles from './loggedInView.module.scss';

const LoggedInView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { userJourneyState } = useUserJourney();

  const backButtonPages = [MY_COVER, PRODUCT_UPGRADE_JOURNEY, PRODUCT_UPGRADE_PAGE, PERSONAL_DETAILS, DOCUMENTS, VEHICLE_DETAILS, ACCOUNT_DETAILS, MEMBER_REWARDS, FAQS, TERMS_CONDITIONS, FORGOT_PASSWORD, UPDATE_PASSWORD, PAYMENT_ISSUE];

  const checkPreviousPage = () => {
    // If the last page was the Login page re-route the customer to the Membership Overview page
    if (userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2] === LOGIN) {
      navigate(ROOT);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.container}>
      {backButtonPages.includes(location.pathname) && <BackButton onClickHandler={checkPreviousPage} />}
      <div className="col-md-12 logged-in-routes-container">
        <Outlet />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.session.isLoggedIn,
  policyLockToken: state.session.policyLockToken,
});

export default connect(mapStateToProps)(LoggedInView);
