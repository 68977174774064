import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { useNavigate } from 'react-router-dom';
import { loggerFunc } from 'utils/logger';
import useGlobalDataLayer from 'hooks/renewal/useGlobalDataLayer';
import adobeOfferStatusConstants from 'constants/adobeOfferStatusConstants';
import infoIconSvg from 'assets/images/button/info-blue.svg';
import { setRenewalDiscountShown } from 'actions/sessionActions';
import {
  setRenewalPageModel,
  setRenewalFeatureTag,
  setRenewalVehicles,
  setRenewalAutoRenew,
  setRenewalPaperless,
} from 'actions/renewalActions';
import { dispatch } from '../../../store';
import { ELIGIBLE } from '../../../constants/policyInfoConstants';
import { RENEWAL_ERROR } from '../../../constants/routeConstants';
import { GetRenewalData } from '../../../services/MyRacService';
import useViewport from '../../../utils/useViewportHelper';
import trackEvent from '../../../services/AnalyticsService';
import styles from './renewal.module.scss';
import Spinner from '../../../components/Spinner';
import Vehicles from './Contents/Vehicles';
import Contents from './Contents/Contents';
import ToggleBox from './Contents/ToggleBox';
import Header from './Contents/Header';
import FreeLoyaltyRewards from './MoreForMoreExtras/FreeLoyaltyRewards';
import PaidUpgrades from './MoreForMoreExtras/PaidUpgrades';
import MoreForMoreExtras from './MoreForMoreExtras/MoreForMoreExtras';
import Basket from './Basket/Basket';
import FlexBasket from './Basket/FlexBasket';

const Renewal = () => {
  const pageModel = useSelector((state) => state?.renewal?.pageModel);
  const { featureTag, toggles, vehicles, chosenMonthlyFlex } = useSelector((state) => state?.renewal?.basket);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { isMobile } = useViewport();

  // Data layer for eVar tracking using Adobe/Ensighten
  useGlobalDataLayer();

  // Object
  const listItem = (item) => (item && <Contents key={item?.header} header={item?.header} body={item?.body} modal={item?.modal} borderless />);
  // Array
  const listItems = (list) => list.map((item) => item && <Contents key={item?.header} header={item?.header} body={item?.body} modal={item?.modal} />);

  const logger = loggerFunc('Renewals');

  const handleAutoRenewToggleChange = (toggleSelected) => {
    if (toggleSelected) {
      trackEvent(`${featureTag}_AutoRenew_OptIn`);
    }
    dispatch(setRenewalAutoRenew(toggleSelected));
  };

  const handlePaperlessToggleChange = (toggleSelected) => {
    if (toggleSelected) {
      trackEvent(`${featureTag}_Efulfil_OptIn`);
    }
    dispatch(setRenewalPaperless(toggleSelected));
  };

  // Function added because the same content is required but to be displayed on different areas of the page for desktop and mobile
  const termsConditions = () => (
    <>
      {pageModel?.adobeOfferStatus === adobeOfferStatusConstants.AdobeOffersAvailable && (
        <>
          <div><b>{ReactHtmlParser(pageModel?.rewardsTermsAndConditions?.header)}</b></div>
          <div>{ReactHtmlParser(pageModel?.rewardsTermsAndConditions?.body)}</div>
        </>
      )}
      {pageModel?.discountModel?.isDiscountedPrice && pageModel?.discountTermsAndConditions && (
        <>
          <div><b>{ReactHtmlParser(pageModel?.discountTermsAndConditions?.header)}</b></div>
          <div>{ReactHtmlParser(pageModel?.discountTermsAndConditions?.body)}</div>
        </>
      )}
    </>
  );

  // Renewal API request that will check eligibility and return page data payload if successful or redirect if not
  const fetchRenewalEligibility = async () => {
    try {
      const response = await GetRenewalData();
      // Payload determines eligibility
      if (response?.payload) {
        if (response?.payload?.eligibility !== ELIGIBLE) {
          // Redirect to error page passing eligibility status in order to switch between relevant content
          trackEvent('MyRAC_Renewal_Ineligible');
          navigate(RENEWAL_ERROR, { state: { isPaymentError: false, eligibility: response?.payload?.eligibility }, replace: true });
        } else {
          logger.debug('starting consumer duty renewal journey');
          // Discount values object added to Redux store (session) for data to be easily accessible
          const discountModel = response.payload?.discountModel;
          if (discountModel) {
            // Set discount flag in session store
            dispatch(setRenewalDiscountShown({
              basketDiscountText: discountModel.basketDiscountText,
              isDiscountedPrice: discountModel.isDiscountedPrice,
              originalPrice: discountModel.originalPrice,
            }));
            // Add discount code to Adobe data layer store
            window.globalDataLayer.MyRAC_DiscountCode = response.payload?.discountModel?.discountCode;
          }

          // Get PDP content
          dispatch(setRenewalPageModel(response.payload));

          // Set Adobe feature tag
          let feature;
          if (response.payload?.isMonthlyLongerTermPolicy) {
            // Monthly LTP
            feature = 'MyRAC_Renewal_MLTP';
          } else {
            // Default
            feature = 'MyRAC_Renewal';
          }
          if (response?.payload?.adobeOfferStatus === adobeOfferStatusConstants.AdobeOffersAvailable) {
            trackEvent(`${feature}_LandingPage_Offer`);
          } else {
            trackEvent(`${feature}_LandingPage`);
          }
          // MYR-14728
          trackEvent('MyRAC_Renewal_PDP_Viewed');
          // Setting payment method Adobe tag (moved from policyInfo)
          window.globalDataLayer.MyRAC_PaymentMethod = response.payload?.paymentMethod;

          // Adobe tag switch
          dispatch(setRenewalFeatureTag(feature));

          window.globalDataLayer.MyRAC_Upgrades_Displayed = response.payload?.paidUpgrades?.length > 0 ? response.payload.paidUpgrades.map((upgrade) => upgrade.title) : [];

          // Set vehicles on policy
          if (response?.payload?.vehicles && vehicles.length === 0) {
            dispatch(setRenewalVehicles(response?.payload?.vehicles));
          }

          if (response?.payload?.vehicles?.length > 0) {
            trackEvent(`${feature}_Vehicle_Displayed`);
          }
          setLoading(false);
        }
      }
    } catch (e) {
      // Redirect to error page cause being issue with the request
      trackEvent('MyRAC_Renewal_Server_Error');
      setLoading(false);
      navigate(RENEWAL_ERROR, { state: { isPaymentError: false }, replace: true });
    }
  };

  useEffect(() => {
    fetchRenewalEligibility();
  }, []);

  if (loading) {
    return <Spinner bootSpinner />;
  }
  return (
    <div className={styles.background}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h1 className={`${styles.title} sessioncamhidetext`}>
              {ReactHtmlParser(pageModel?.renewalTitle)}
            </h1>
            <div className={styles.description}>
              {ReactHtmlParser(pageModel?.renewalDescription)}
            </div>
            {/* Cover, Add-Ons and Extras */}
            <Header text="Check your cover" />
            <div className={`${styles.cover} sessioncamhidetext`}>
              {pageModel?.yourCoverContent?.length > 0 && (
                <>
                  {/* First Cover Item */}
                  {listItem(pageModel?.yourCoverContent[0])}
                  {/* Edit Vehicle */}
                  <Vehicles />
                  {/* Remaining Cover List */}
                  {listItems(pageModel?.yourCoverContent.slice(1))}
                </>
              )}
              {pageModel?.addonsContent?.length > 0 && (
                <>
                  <h2 className="mt-4">{pageModel?.addonsTitle}</h2>
                  {listItems(pageModel?.addonsContent)}
                </>
              )}
              {pageModel?.complimentaryExtrasContent?.length > 0 && (
                <>
                  <h2 className="mt-4">{pageModel?.complimentaryExtrasTitle}</h2>
                  {listItems(pageModel?.complimentaryExtrasContent)}
                </>
              )}
            </div>
            {/* Paid Upgrades */}
            {pageModel?.paidUpgrades?.length > 0
              && (
                <>
                  <Header text="Add to your cover" />
                  <MoreForMoreExtras>
                    <PaidUpgrades />
                  </MoreForMoreExtras>
                </>
              )}
            {/* Free Loyalty Rewards */}
            {pageModel?.adobeOfferStatus === adobeOfferStatusConstants?.AdobeOffersAvailable
              && (
                <>
                  <Header text="Choose a loyalty reward" />
                  <MoreForMoreExtras>
                    <FreeLoyaltyRewards />
                  </MoreForMoreExtras>
                </>
              )}
            {/* Toggle */}
            {(pageModel?.autoRenewToggle?.isEnabled || pageModel?.goPaperlessToggle?.isEnabled)
              && (
                <Header text="Select your preferences" additionalClass="mt-4" />
              )}
            {pageModel?.autoRenewToggle?.isEnabled && !chosenMonthlyFlex
              && (
                <ToggleBox
                  title={pageModel?.autoRenewToggle?.title}
                  description={ReactHtmlParser(pageModel?.autoRenewToggle?.description)}
                  onToggleChange={() => handleAutoRenewToggleChange(!toggles.autoRenew)}
                  checked={toggles.autoRenew}
                />
              )}
            {pageModel?.autoRenewToggle?.isEnabled && chosenMonthlyFlex
              && (
                // Show alert if Monthly option is selected from FlexBasket
                <div className="alert alert-primary d-flex align-items-top" role="alert">
                  <img src={infoIconSvg} alt="info" height="20" width="20" className={styles.icon} />
                  <div>
                    <h3>Relax, with monthly payments</h3>
                    <p>When you pay monthly you don&apos;t have to worry about renewing your cover. You can cancel anytime.</p>
                  </div>
                </div>
              )}
            {pageModel?.goPaperlessToggle?.isEnabled
              && (
                <ToggleBox
                  title={pageModel?.goPaperlessToggle?.title}
                  description={ReactHtmlParser(pageModel?.goPaperlessToggle?.description)}
                  onToggleChange={() => handlePaperlessToggleChange(!toggles.paperless)}
                  checked={toggles.paperless}
                />
              )}
            {/* Basket that allows users to toggle between annual and monthly payment */}
            {pageModel.isEligibleForMonthlyFlex && (
              <>
                <Header text="Choose how to pay" />
                <FlexBasket />
              </>
            )}
            {/* About making changes to policy */}
            {pageModel?.makingChanges && (
              <div className="px-3">
                <div><b>{ReactHtmlParser(pageModel?.makingChanges?.header)}</b></div>
                <div>{ReactHtmlParser(pageModel?.makingChanges?.body)}</div>
              </div>
            )}
            {/* T&C's placeholder for Desktop */}
            {!isMobile && <div className="px-3 mt-2 mb-5">{termsConditions()}</div>}
          </div>
          {!pageModel.isEligibleForMonthlyFlex && <Basket />}
          {/* T&C's placeholder for Mobile */}
          {isMobile && <div className="px-3 mt-3">{termsConditions()}</div>}
        </div>
      </div>
    </div>
  );
};

export default Renewal;
