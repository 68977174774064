import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InformationMessage from 'components/InformationMessage';
import * as DefaultText from 'configuration/defaultText';
import TieredBenefits from 'panels/TieredBenefits';
import FreeBenefits from 'panels/FreeBenefits';
import { GetContent, ResponseCode } from 'services/ContentService';
import trackEvent from 'services/AnalyticsService';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { filterComponents } from 'utils/ContentHelpers';
import { loggerFunc } from 'utils/logger';
import { useApplicationState } from '../../hooks/applicationState-Context';
import styles from './MemberBenefits.module.scss';

const logger = loggerFunc('MemberBenefits');

const MemberRewards = ({ customer, policy }) => {
  const [pageModel, setPageModel] = useState(null);
  const [error, setError] = useState(null);
  const [cardInformation, setCardInformation] = useState(false);
  const [memberOffers, setMemberOffers] = useState(false);

  const { appState, setPageLoading } = useApplicationState();

  const processPageModelContent = (pageData) => {
    if (pageData.responseCode === ResponseCode.ERROR) {
      return setError(DefaultText.APIError);
    }

    setCardInformation(filterComponents('cardinformation', pageData.payload));
    setMemberOffers(filterComponents('memberoffers', pageData.payload));

    // eslint-disable-next-line no-param-reassign
    pageData = {
      cardInformation,
      memberoffer: memberOffers,
      enhancedBenefits: {
        orange: memberOffers ? [memberOffers.enhancedbenefits[0], memberOffers.enhancedbenefits[1]] : [],
        others: memberOffers ? [memberOffers.enhancedbenefits[2], memberOffers.enhancedbenefits[3]] : [],
      },
      yearText: cardInformation.yearstext,
    };

    setPageModel(pageData);
    return null;
  };

  const fetchPageData = async () => {
    try {
      if (!pageModel) {
        const pageData = await GetContent('loggedinpages', 'Membership benefits', ['componentitems']);
        if (pageData) {
          processPageModelContent(pageData);
          setPageLoading(false);
        }
      }
      trackEvent('Page Change - member-benefits');
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    fetchPageData();
  });

  if (customer.custInfo.hasError || policy.policyInfo.hasError || error) {
    return <InformationMessage text={DefaultText.APIError} />;
  }

  return (
    !appState.loading.status && (
      <div className={styles.container} data-testid="MemberBenefitsContainer">
        <PageTitle text="Member rewards" />
        <TieredBenefits freebenefits={pageModel?.enhancedBenefits} />
        <FreeBenefits freebenefits={pageModel?.memberoffer} />
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
});

export default connect(mapStateToProps)(MemberRewards);
