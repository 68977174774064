import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compareDesc from 'date-fns/compareDesc';
import ComponentView from 'common/ComponentView';
import InformationMessage from 'components/InformationMessage';
import RenderLink from 'components/RenderLink';
import * as DefaultText from 'configuration/defaultText';
import AdvertorialPanel from 'panels/AdvertorialPanel';
import DocumentsListing from 'panels/DocumentsListing';
import trackEvent from 'services/AnalyticsService';
import { GetContent, GetTileContent, MapAdvertPanel } from 'services/ContentService';
import { ResponseCode } from 'services/models/httpResponse';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { filterComponents } from 'utils/ContentHelpers';
import { loggerFunc } from 'utils/logger';
import { DOCUMENT_TYPE_BOOKLET } from '../../constants/documentConstants';
import AbstractGrid from './MembershipOverview/GridLayout/AbstractGrid';
import TileSets from '../../utils/tileSets.enum';
import { useApplicationState } from '../../hooks/applicationState-Context';
import { RENEWAL } from '../../constants/routeConstants';

const logger = loggerFunc('MembershipDocuments');

export const MembershipDocuments = (props) => {
  const {
    customer: { custInfo: { Id: customerId, hasError: customerHasError } },
    policy: { policyInfo: { hasError: policyHasError } },
    session: { memberNo, renewBannerShown, renewBannerImgUrl },
    tiles: { [TileSets.documentsPageAds]: documentTiles },
    documents,
  } = props;

  const [pageModel, setPageModel] = useState();
  const [errorText, setErrorText] = useState();
  const [adobeTag, setAdobeTag] = useState();
  const [splitDocuments, setSplitDocuments] = useState({});
  const { appState, setPageLoading } = useApplicationState();

  const renewalBannerAnalytics = () => {
    // Split image url into array
    const urlPath = renewBannerImgUrl.split('/');
    // Get last item from array and slice off file extension
    const filename = urlPath[urlPath.length - 1].slice(0, -4);
    if (filename) {
      // Event fired on page load
      trackEvent(`MyRAC_Documents_${filename}_Shown`);
      // Event set in state and fired through RenderLink action
      setAdobeTag(`MyRAC_Documents_${filename}_Clicked`);
    }
  };

  const processPageModelContent = (result) => {
    if (result.responseCode === ResponseCode.ERROR) {
      setPageModel({ error: DefaultText.APIError });
      return;
    }

    const membershipDocument = filterComponents('membershipdocumentcomponent', result.payload);

    const pageData = {
      title: membershipDocument.title,
      advertorial: MapAdvertPanel(result.payload),
      documentCMS: {
        subheading: membershipDocument.subheading,
        documentIcon: {
          icon: membershipDocument.documenticon.url,
          title: membershipDocument.documenticon.title,
        },
        downloadIcon: {
          icon: membershipDocument.downloadimage.url,
          title: membershipDocument.downloadimage.title,
        },
        downloadlinktext: membershipDocument.downloadlinktext,
      },
    };

    setPageModel(pageData);
  };

  const orderDocuments = (documentsArray) => documentsArray.sort((a, b) => {
    const result = compareDesc(new Date(a.DocumentDate), new Date(b.DocumentDate));
    return result;
  });

  const extractLatestBooklet = (documentsArray) => {
    for (let i = 0; i < documentsArray.length; i++) {
      if (documentsArray[i].DocumentType === DOCUMENT_TYPE_BOOKLET) {
        return documentsArray.splice(i, 1)[0];
      }
    }
    return null;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setupDocuments = (localDocuments) => {
    const orderedDocuments = orderDocuments(localDocuments);
    const booklet = extractLatestBooklet(orderedDocuments);

    const latestDocuments = orderedDocuments.splice(0, 2);
    if (booklet) {
      latestDocuments.push(booklet);
    }
    return { currentDocuments: latestDocuments, olderDocuments: orderedDocuments };
  };

  const handleError = (e, text) => {
    setErrorText(text);
    logger.error(text, e);
  };

  useEffect(() => {
    async function fetchContentData() {
      try {
        const pageData = await GetContent('loggedinpages', 'Membership documents', ['componentitems']);
        processPageModelContent(pageData);
      } catch (e) {
        handleError(e);
      }
    }

    if (!pageModel && customerId) {
      fetchContentData().then(() => {
        GetTileContent(customerId, memberNo, TileSets.documentsPageAds, false).then(setPageLoading(false))
          .catch((e) => {
            setPageLoading(false);
            handleError(e);
          });
      })
        .catch((e) => {
          setPageLoading(false);
          handleError(e);
        });
    }

    // Analytics
    if (renewBannerImgUrl) {
      renewalBannerAnalytics();
    }
    trackEvent('Page Change - documents');

    const localDocumentsCopy = documents.documents?.payload ? [...documents.documents.payload] : [];
    const myDocuments = setupDocuments(localDocumentsCopy);

    setSplitDocuments(myDocuments);
  }, []);

  const displayPersonalDetailsContent = () => {
    if (customerHasError || policyHasError || pageModel?.error) {
      return <InformationMessage text={DefaultText.APIError} />;
    }

    return (
      <ComponentView errorText={errorText} onErrorModalClose={() => { setErrorText(undefined); }}>
        <PageTitle text="Membership documents" />
        <div className="myrac-docs-content row my-3">
          <div className="col-sm-9 col-12 float-left">
            {splitDocuments?.currentDocuments && (<DocumentsListing documents={splitDocuments?.currentDocuments} downloadLinkTitle={pageModel.documentCMS.downloadlinktext} documentsIcon={pageModel.documentCMS.documentIcon.icon} documentStatus="current" />)}
            {splitDocuments?.olderDocuments.length > 0 && (<DocumentsListing documents={splitDocuments?.olderDocuments} downloadLinkTitle={pageModel.documentCMS.downloadlinktext} documentsIcon={pageModel.documentCMS.documentIcon.icon} documentStatus="older" />)}
          </div>
          <div className="col-sm-3 col-12 float-left">
            {renewBannerShown && renewBannerImgUrl
              ? (
                <RenderLink url={RENEWAL} analytics={adobeTag}>
                  <img src={renewBannerImgUrl} width="100%" alt="Renew Now" />
                </RenderLink>
              )
              : (<AbstractGrid columns={1} tiles={documentTiles} />)}
          </div>
        </div>
        <AdvertorialPanel advertorial={pageModel?.advertorial} />
      </ComponentView>
    );
  };

  return ((appState?.loading.status && !pageModel) ? null : displayPersonalDetailsContent());
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  documents: state.documents,
  featureFlags: state.featureToggle,
  tiles: state.content.tiles,
});

export default connect(mapStateToProps)(MembershipDocuments);
