import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InformationMessage from 'components/InformationMessage';
import * as DefaultText from 'configuration/defaultText';
import * as messageConstants from 'constants/cms/messageConstants';
import AdvertorialPanel from 'panels/AdvertorialPanel';
import CommunicationPreferencesPanel from 'views/MyRac/AccountSettings/Panels/CommunicationPreferencesPanel';
import DocumentPreferences from 'views/MyRac/AccountSettings/Panels/DocumentPreferencesPanel';
import EmailAddressPanel from 'views/MyRac/AccountSettings/Panels/EmailAddressPanel';
import PasswordPanel from 'views/MyRac/AccountSettings/Panels/PasswordPanel';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { GetContent, ResponseCode, MapAdvertPanel, mapImage, mapURL } from 'services/ContentService';
import { getMessage } from 'services/MessageService';
import { filterComponents } from 'utils/ContentHelpers';
import { loggerFunc } from 'utils/logger';
import AutorenewalPanel from './Panels/AutorenewalPanel';
import { useApplicationState } from '../../../hooks/applicationState-Context';

const logger = loggerFunc('AccountSettings');

const AccountSettings = ({ policy, customer }) => {
  const { appState, setPageLoading } = useApplicationState();

  const [pageModel, setPageModel] = useState(false);
  const [emailPanelData, setEmailPanelData] = useState(undefined);
  const [passwordPanelData, setPasswordPanelData] = useState(undefined);
  const [communicationPrefData, setCommunicationPrefData] = useState(undefined);
  const [documentPrefData, setDocumentPrefData] = useState(undefined);
  const [autoRenewPanelData, setAutoRenewPanelData] = useState(undefined);

  const getPageModel = async () => {
    if (!pageModel) {
      const pageData = await GetContent(
        'loggedinpages',
        'Account Details',
        ['componentitems', 'componentitems.changepasswordfields'],
      );

      if (pageData.responseCode === ResponseCode.ERROR) {
        setPageModel({ error: DefaultText.APIError });
      } else {
        // Get account settings pagemodel data from CMS
        const accountSettings = filterComponents('accountsettings', pageData?.payload);

        setEmailPanelData({
          title: accountSettings?.emailaddresstitle,
          button: {
            text: accountSettings?.changeemailaddressbuttontext,
          },
          messages: {
            successTitle: getMessage(messageConstants?.accountSettings?.SUCCESS_TITLE),
            successMessage: getMessage(messageConstants?.accountSettings?.SUCCESS_TEXT),
            failTitle: getMessage(messageConstants?.generalMessages?.UPDATE_FAILURE_TITLE),
            failMessage: getMessage(messageConstants?.generalMessages?.UPDATE_FAILURE_ERROR),
            invalidEmailFormat: getMessage(messageConstants?.accountSettings?.INVALID_EMAIL_TEXT),
          },
          modalOk: getMessage(messageConstants?.generalMessages?.OK_BUTTON_TEXT),
          submitButton: accountSettings?.submitbutton,
          backButton: getMessage(messageConstants?.generalMessages?.BACK_BUTTON_TEXT),
        });

        const passwordPanelDataHolder = {
          title: accountSettings?.passwordtitle,
          password: accountSettings?.passwordtexttodisplay,
          link: mapURL(accountSettings?.changepasswordlink),
        };

        setCommunicationPrefData({
          title: accountSettings?.communicationpreferencestitle,
          button: accountSettings?.changecommunicationpreferenceslinktext,
          text: accountSettings?.changecommunicationpreferencestext,
          submit: accountSettings?.submitbutton,
          messages: {
            successTitle: getMessage(messageConstants?.generalPreferences.SUCCESS_TITLE),
            successMessage: getMessage(messageConstants?.generalPreferences.SUCCESS_TEXT),
            failTitle: getMessage(messageConstants?.generalMessages?.UPDATE_FAILURE_TITLE),
            failMessage: getMessage(messageConstants?.generalMessages?.UPDATE_FAILURE_ERROR),

          },
          image: mapImage(accountSettings?.communicationimage),
          modalOk: getMessage(messageConstants?.generalMessages?.OK_BUTTON_TEXT),
        });

        const documentPrefDataHolder = {
          title: accountSettings?.documentpreferencestitle,
          button: accountSettings?.changedocumentpreferenceslinktext,
          text: accountSettings?.changedocumentpreferencestext,
          paperless: {
            warning: accountSettings?.environmenttext,
            image: mapImage(accountSettings?.paperlessimage),
          },
          options: {
            online: accountSettings?.onlineoptiontext,
            paper: accountSettings?.paperoptiontext,
          },
          submit: accountSettings?.submitbutton,
          messages: {
            successTitle: getMessage(messageConstants?.generalPreferences.SUCCESS_TITLE),
            successMessage: getMessage(messageConstants?.generalPreferences.SUCCESS_TEXT),
            failTitle: getMessage(messageConstants?.generalMessages?.UPDATE_FAILURE_TITLE),
            failMessage: getMessage(messageConstants?.generalMessages?.UPDATE_FAILURE_ERROR),
          },
          image: mapImage(accountSettings?.communicationimage),
          modalOk: getMessage(messageConstants?.generalMessages?.OK_BUTTON_TEXT),
        };

        setAutoRenewPanelData({
          title: accountSettings?.autorenew.autorenewpreferencestitle,
          button: accountSettings?.autorenew.changeautorenewpreferenceslinktext,
          text: accountSettings?.autorenew.changeautorenewpreferencestext,
          options: {
            optIn: 'Opt in',
            optOut: 'Opt out',
          },
          optOutDisabledMessage: accountSettings?.autorenew.alreadyrenewedtext,
          submit: accountSettings?.submitbutton,
          messages: {
            confirmDialogue: {
              title: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_OUT_SUBMIT_TITLE),
              body: {
                optIn: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_IN_SUBMIT_DESCRIPTION),
                optOut: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_OUT_SUBMIT_DESCRIPTION),
              },
              confirmButton: 'Confirm',
              cancelButton: 'Cancel',
            },
            optInDialogue: {
              title: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_UNABLE_TO_CHANGE_TITLE),
              body: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_UNABLE_TO_CHANGE_DESCRIPTION),
            },
            RenewalInProgressDialogue: {
              title: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_RENEWAL_IN_PROGRESS_TITLE),
              body: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_RENEWAL_IN_PROGRESS_DESCRIPTION),
            },
            successTitle: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_OUT_SUCCESS_TITLE),
            successMessage: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_OUT_SUCCESS_DESCRIPTION),
            failTitle: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_OUT_ERROR_TITLE),
            failMessage: getMessage(messageConstants?.renewalNotices?.AUTO_RENEW_OPT_OUT_ERROR_DESCRIPTION),
          },
          image: mapImage(accountSettings?.autorenew.autorenewpreferencesimage),
          modalOk: getMessage(messageConstants?.generalMessages?.OK_BUTTON_TEXT),
          tagging: accountSettings?.autorenew.tagging,
        });

        setPageModel({
          advertorial: MapAdvertPanel(pageData.payload),
          title: pageData.payload.pagetitle,
          heading: pageData.payload.heading,
          preferencesHeader: accountSettings?.preferencesheader,
        });

        passwordPanelDataHolder.link.url = '/update-password';
        passwordPanelDataHolder.link.isExternal = false;
        documentPrefDataHolder.paperless.image.title = 'Go paperless';

        setPasswordPanelData(passwordPanelDataHolder);
        setDocumentPrefData(documentPrefDataHolder);
      }
      // Stop appState page loading spinner
      setPageLoading(false);
    }
  };

  useEffect(() => {
    try {
      getPageModel();
    } catch (e) {
      logger.error(e);
    }
  }, []);

  const render = () => {
    if (customer?.custInfo?.hasError || !pageModel) {
      return <InformationMessage text={DefaultText.APIError} />;
    }

    return (
      <>
        <PageTitle text={pageModel?.heading} />
        <div className="row">
          <div className="col-sm-12 col-12">
            <div className="row.make-equal">
              <div className="col-sm-12 col-12">
                <div className="row display-flex">
                  <EmailAddressPanel pageModel={emailPanelData} />
                  <PasswordPanel pageModel={passwordPanelData} />
                </div>
                <div className="row display-flex">
                  <div className="col-sm-12 col-12 account-preferences">
                    <PageTitle text={pageModel?.preferencesHeader} />
                    <div className="din-font px-3">
                      <CommunicationPreferencesPanel pageModel={communicationPrefData} />
                      <DocumentPreferences pageModel={documentPrefData} />
                      {
                        policy?.policyInfo?.AutoRenew?.IsAutoRenewDisplayEligible ? <AutorenewalPanel pageModel={autoRenewPanelData} /> : null
                      }
                    </div>
                  </div>
                </div>
                <AdvertorialPanel advertorial={pageModel?.advertorial} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    (appState?.loading?.status) ? null
      : render()
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
});

export default connect(mapStateToProps)(AccountSettings);
