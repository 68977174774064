import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROOT } from 'constants/routeConstants';
import useReferrerParam from 'hooks/useReferrerParam';
import BackButton from 'shared/components/BackButton/BackButton';
import styles from './Container.module.scss';

export const CONTAINER_TYPES = {
  GREY_BACKGROUND: 'GREY_BACKGROUND',
  CONFIRMATION: 'CONFIRMATION',
};

const Container = ({ children, type }) => {
  const isAppView = useReferrerParam();
  const navigate = useNavigate();

  const onBackClick = async () => {
    // placeholder/todo for trackEvent('MyRAC_OrangePointsRewardsScreen_DirectDebit_Back');
    navigate(ROOT);
  };

  let styleClasses = '';
  if (type === CONTAINER_TYPES.GREY_BACKGROUND) {
    styleClasses = styles.containerBackground;
  }
  if (type === CONTAINER_TYPES.CONFIRMATION) {
    styleClasses = styles.containerConfirmation;
  }

  return (
    <div className={`container ${styleClasses}`}>
      {!isAppView && type !== CONTAINER_TYPES.CONFIRMATION && <BackButton onClickHandler={onBackClick} />}
      {type === CONTAINER_TYPES.CONFIRMATION || type === CONTAINER_TYPES.GREY_BACKGROUND ? (
        <div className={styles.content}>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Container;
