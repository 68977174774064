import React from 'react';
import styles from './PageTitle.module.scss';

const PageTitle = ({ text }) => (
  <div className={styles.pageTitle}>
    <h3>{text}</h3>
  </div>
);

export default PageTitle;
