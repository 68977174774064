import React, { useState, useEffect } from 'react';
import Spinner from 'components/Spinner';
import slide1 from 'assets/images/orangepoints/slide-1.gif';
import slide2 from 'assets/images/orangepoints/slide-2.png';
import './OrangePointsSlider.scss';

const useSlider = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let slider;
    const script = document.createElement('script');
    script.id = 'tiny-slider';
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/min/tiny-slider.js';
    script.crossorigin = true;
    script.addEventListener('load', async () => {
      setLoading(false);
      // eslint-disable-next-line no-undef
      slider = tns({
        container: '#tns',
        items: 1,
        slideBy: 'page',
        controls: false,
        navPosition: 'bottom',
        mouseDrag: true,
        preventScrollOnTouch: true,
        loop: false,
        swipeAngle: 90,
      });
    });
    document.body.appendChild(script);

    return () => {
      slider.destroy();
      document.body.removeChild(script);
    };
  }, []);

  if (loading) {
    return <Spinner bootSpinner />;
  }

  return (
    <div className="sliderContainer">
      <div id="tns">
        <div className="sliderItem">
          <img src={slide1} alt="Orange points slider item 1" />
          <div className="sliderItemCaption">
            <h3>The UK&apos;s first and only breakdown loyalty points programme</h3>
            <p>Exchange your points for discounts that suit you.</p>
          </div>
        </div>
        <div className="sliderItem">
          <img src={slide2} alt="Orange points slider item 2" />
        </div>
      </div>
    </div>
  );
};

export default useSlider;
