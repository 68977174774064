import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import trackEvent from 'services/AnalyticsService';
import { ORANGE_POINTS_JOURNEY } from 'constants/routeConstants';
import OrangePointsSlider from 'components/OrangePointsSlider';
import Button from 'shared/components/Button/Button';

const OrangePointsModal = (props) => {
  const { action } = props;

  const navigate = useNavigate();

  const button = {
    color: '#f95108',
    backgroundColor: '#fff',
  };

  useEffect(() => {
    trackEvent('MyRAC_OrangePoints_Pop_Up_Displayed');
  }, []);

  return (
    <>
      <div className="modal myrac Modal__overflow d-flex align-items-center" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content white bg-orange">
            <div className="modal-header float-right border-0 z-1 position-absolute w-100">
              <button
                title="Close"
                type="button"
                className="close ascii-cross btn-close btn-close-white fs-5 opacity-100 p-3"
                aria-hidden="true"
                onClick={() => {
                  trackEvent('MyRAC_OrangePoints_Pop_Up_Closed');
                  action();
                }}
              />
            </div>
            <div className="modal-body text-center p-1">
              <OrangePointsSlider />
            </div>
            <div className="px-3 mb-4">
              <Button
                fullWidth
                onClickHandler={() => {
                  trackEvent('MyRAC_OrangePoints_Pop_Up_CTA_Clicked');
                  navigate(ORANGE_POINTS_JOURNEY);
                }}
                buttonText="Spend your points"
                buttonStyles={button}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in Modal__block" />
    </>
  );
};

export default OrangePointsModal;
