import React from 'react';
import { ReactComponent as BackIcon } from 'images/icons/caret-left.svg';
import styles from './BackButton.module.scss';

const BackButton = ({ onClickHandler }) => (
  <div className={styles.wrapper}>
    <button className={styles.button} type="button" onClick={onClickHandler}>
      <BackIcon />
      <u>Back</u>
    </button>
  </div>
);

export default BackButton;
