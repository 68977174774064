import React from 'react';
import styles from './RemindersCard.module.scss';
import ReminderItem2 from './ReminderItem2';
import Button from '../../../shared/components/Button/Button';
import { createReminderItem2DateText } from '../../../utils/vehicleHelpers';

const RemindersCard = ({ pageModel, upcomingRemindersList, reminderVehicles, smallMessage, reminderSMRData }) => {
  const displayReminders = () => {
    // Sort the reminders in date order.
    upcomingRemindersList.sort((a, b) => {
      const c = new Date(a.date);
      const d = new Date(b.date);
      return c - d;
    });

    const componentList = [];

    // Iterate the first three elements of sorted list.
    upcomingRemindersList.slice(0, 3).forEach((upcomingReminder) => {
      const vehicle = reminderVehicles?.find((reminderVehicle) => upcomingReminder.vehicleId === reminderVehicle.vehicleId);
      componentList.push(
        <ReminderItem2
          key={upcomingReminder.vehicleId + upcomingReminder.reminderType}
          title={upcomingReminder.reminderType}
          registration={vehicle.Registration}
          dateReminderText={createReminderItem2DateText(upcomingReminder)}
          reminderType={upcomingReminder.reminderType}
          reminderSMRBtn={reminderSMRData?.remindersMoreInfo}
        />,
      );
    });
    return componentList;
  };

  return (
    <div className={styles.container}>
      <div className={styles.borderContainer}>
        { upcomingRemindersList?.length ? (
          <>
            <h4 className={styles.title}>Latest reminders</h4>
            <div className={styles.remindersContainer}>
              {displayReminders()}
            </div>
          </>
        ) : null}
        <Button
          buttonText="Find a garage"
          chevronWhite
          onClickHandler={() => {
            window.open(pageModel.garageUrl.href, '_blank').focus();
          }}
        />
      </div>
      { reminderVehicles?.length ? (
        <p className={styles.smallPrint}>{ smallMessage }</p>
      ) : null }
    </div>
  );
};

export default RemindersCard;
